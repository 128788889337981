import { CardBody, Typography } from "@material-tailwind/react";
import Chart from "react-apexcharts";

// If you're using Next.js please use the dynamic import for react-apexcharts and remove the import from the top for the react-apexcharts
// import dynamic from "next/dynamic";
// const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

export default function LineChart({
  data,
  categories,
  title,
  yaxis,
  description2,
}) {
  const numberWithCommas = (num) => {
    return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  const mapMonths = (monthNumbers) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNumbers.map((month) => monthNames[month - 1]);
  };
  const chartConfig = {
    type: "line",
    height: 270,
    series: [
      {
        name: "Ending Cash",
        data: data,
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 330,
          },
        },
      },

      title: {
        show: "",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#020617"],
      stroke: {
        lineCap: "round",
        curve: "smooth",
      },
      markers: {
        size: 0,
      },

      xaxis: {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
          color: "#000", // Set the color of the X-axis border
          offsetX: 0,
          offsetY: -1,
        },

        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
        categories: mapMonths(categories),
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: "#000", // Set the color of the X-axis border
          offsetX: -1,
          offsetY: 0,
        },
        labels: {
          show: "true",
          formatter: (value) => ` $${numberWithCommas(value)} `, // This adds a $ sign before each value

          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
        title: {
          text: yaxis,
          fontFamily: "Euclid Circular B",
        },
      },
      grid: {
        show: true,
        borderColor: "#dddddd",
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 5,
          right: 20,
        },
      },
      fill: {
        opacity: 0.8,
      },
      tooltip: {
        theme: "dark",
      },
    },
  };

  return (
    <div className="w-full flex flex-col items-center bg-white p-2 md:p-8 rounded-xl" style={{ boxShadow: "20px 20px 64px 0px rgba(60, 45, 137, 0.05)"}}>
      <div className="flex flex-col items-center text-center">
        <Typography
          color="black"
          className="   text-black text-sm font-bold "
          style={{ fontFamily: "Euclid Circular B" }}
        >
          {title}{" "}
        </Typography>
      </div>
      <CardBody className="px-2 pt-0 w-full">
        <Chart {...chartConfig} />
      </CardBody>
    </div>
  );
}
