import { Progress } from "@material-tailwind/react";
import { useEffect, useState } from "react";

export const ProgressBar = ({
  value,
  background = true,
  direction = "",
  height = "",
}) => {
  const [color, setColor] = useState("red");
  useEffect(() => {
    if (value <= 20) {
      setColor("red");
    } else if (value < 80) {
      setColor("amber");
    } else {
      setColor("teal");
    }
  }, [color, value]);
  return (
    <Progress
      value={value}
      color={color}
      variant="filled"
      className="bg-inherit h-3 	"
      style={{
        background: background ? "#3C2D89" : "inherit",
        direction: direction,
        height: height,
      }}
      //   size="lg"
    />
  );
};
