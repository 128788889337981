import { DetailsCard } from "./Cards/DetailsCard";
import LineChart from "./LineChart";
import ConfettiExplosion from "react-confetti-explosion";
import { PilotGPTCard } from "./Cards/PilotGPTCard";
import DarkSparkle from "./../assets/dark_sparkle.svg";
import LargeDetailsCard from "./Cards/LargeDetailsCard";
import { CircleProgressBarMini } from "./CircularProgressBarMini";
import { CheckIcon, CrossIcon } from "./Cards/DetailsCard";

const CardsPage = ({ finalData }) => {

  const numberWithCommas = (num) => {
    return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }


  return (
    <div id="cards_section" className="flex flex-col items-center pt-16 px-16 pb-20 md:px-16 lg:px-8 xl:px-2 gap-2 max-w-6xl">
      {finalData['pilot_customer'] && (
        <div className="flex flex-col w-full text-white items-center justify-center text-center p-10 mb-10 rounded-xl shadow-xl" style={{ 
          fontFamily: "Euclid Circular B",
          fontWeight: 700,
          backgroundColor: "#825BEB",
        }}>
          <div className="flex w-full text-xl md:text-4xl items-center justify-center mb-4">
            Looks like you&rsquo;re a Pilot customer!
          </div>
          <div className="flex w-full text-md md:text-xl items-center justify-center" style={{ fontWeight: "normal"}}>
            Your books are reviewed by 50+ automated checks every month, plus an additional review by a manager, so you've already gotten an even more rigorous version of these checks.
          </div>
        </div>) }
      <div
        id="general_business_health_section" className="flex md:flex-row flex-col w-full items-center justify-center md:justify-between "
        style={{ fontFamily: "Euclid Circular B" }}
      >
        <div className="flex flex-col text-left">
          <div className="text-black text-4xl font-outfit font-bold">
            General Business Health
          </div>
          <div className="text-black text-sm">
            { !finalData["runway"]["RUNWAY"]["PROFITABLE"] ? (
              <>
                {"You have "}
                <b style={{ color: "#5F2EE5" }}>
                  {finalData["runway"]["RUNWAY"]["RUNWAY_IN_MONTHS"] + " months"}
                </b>
                {" of runway left. Your burn rate is "}
                <b style={{ color: "#5F2EE5" }}>
                  {"$" + numberWithCommas(finalData["runway"]["RUNWAY"]["BURN_RATE"]) + " a month."}
                </b>
              </>) : (
                <>
                  {"You are profitable! You make "}
                  <b style={{ color: "#5F2EE5" }}>
                    {"$" + numberWithCommas(finalData["runway"]["RUNWAY"]["MONTHLY_PROFIT"]) + " a month in profit."}
                  </b>
                </>
              )}
          </div>
        </div>

        {finalData["sub_scores"]["health"] === 100 && (
          <ConfettiExplosion duration={5000}></ConfettiExplosion>
        )}
      </div>
      <div id="line_chart_section" className="flex w-full flex-row justify-evenly gap-10 mt-8 flex-wrap ">
        <div className="flex flex-col w-full">
          <LineChart
            title={"Cash Balance"}
            data={finalData["runway"]["RUNWAY"].ENDING_CASH.map((item) =>
              parseFloat(item.ending_cash)
            )}
            categories={finalData["runway"]["RUNWAY"].ENDING_CASH.map((item) =>
              parseFloat(item.month)
            )}
            yaxis={"Ending Cash"}
          ></LineChart>
        </div>
      </div>
      <div
        id="balance_sheet_review_section" className="flex mt-12 md:flex-row flex-col w-full items-center justify-center md:justify-between html2pdf__page-break "
        style={{ fontFamily: "Euclid Circular B" }}
      >
        <div
          className="text-black text-4xl my-4  text-center font-bold"
          style={{ fontFamily: "Euclid Circular B" }}
        >
          {" "}
          Balance Sheet Review
        </div>
        <div className="md:w-1/5 w-full flex flex-row items-center justify-center gap-2 ">
          <div className="text-black text-sm ">
            {" "}
            Your Score:{" "}
            <span className="font-bold mx-1" style={{ color: "#5F2EE5" }}>
              {finalData["sub_scores"]["balance_sheet"]}/100
            </span>
          </div>
          <CircleProgressBarMini
            percentage={finalData["sub_scores"]["balance_sheet"]}
          ></CircleProgressBarMini>
        </div>
      </div>
      <div className="flex w-full flex-row justify-evenly lg:justify-between gap-10 mt-4 flex-wrap lg:px-36">
        <DetailsCard
          check_type={"RECONCILED_BANK_ACCOUNTS"}
          result={finalData["balance_sheet"]["RECONCILED_BANK_ACCOUNTS"]}
        ></DetailsCard>
        <DetailsCard
          check_type={"ZERO_OPENING_BALANCE"}
          result={finalData["balance_sheet"]["ZERO_OPENING_BALANCE"]}
        ></DetailsCard>
        <DetailsCard
          check_type={"NEGATIVE_BALANCES"}
          result={finalData["balance_sheet"]["NEGATIVE_BALANCES"]}
        ></DetailsCard>
        <DetailsCard
          check_type={"NEGATIVE_INVENTORY"}
          result={finalData["balance_sheet"]["NEGATIVE_INVENTORY"]}
        ></DetailsCard>
      </div>
      <div className="flex flex-col w-full my-10 justify-center lg:px-36">
        <div
          className="flex flex-col md:flex-row items-center text-black text-xl my-4  text-left font-bold"
          style={{ fontFamily: "Euclid Circular B" }}
        >
          {" "}
          AI Review - Balance Sheet
          <img
            alt=""
            className="flex items-start justify-start w-4 h-4 mx-1"
            src={DarkSparkle}
          />
          <div
            className=" flex flex-row h-6 rounded-xl mx-2 py-1 px-3 gap-1 text-gray-600 justify-center items-center mb-2 md:mb-0"
            style={{
              fontSize: "11px",
              background: "#E0E0E0",
              width: "fit-content",
              fontFamily: "Euclid Circular B",
              fontWeight: "normal",
              letterSpacing: "1.92px",
            }}
          >
            BETA
          </div>
          <div
            className=" flex flex-row h-6 rounded-xl mx-2 py-1 px-3 gap-1 text-white justify-center items-center"
            style={{
              fontSize: "11px",
              background: finalData["balance_sheet"]["BS"] ? "#47BFA4" : "#E2B62D",
              width: "fit-content",
              fontFamily: "Euclid Circular B",
              letterSpacing: "1.92px",
            }}
          >
            {finalData["balance_sheet"]["BS"] ? <CheckIcon></CheckIcon> : <CrossIcon></CrossIcon>}
          
            {finalData["balance_sheet"]["BS"] ? "LOOKS GOOD" : "INVESTIGATE"}
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div
            className="flex w-full text-black text-sm items-center mb-2"
            style={{ fontFamily: "Euclid Circular B", color: "#656363" }}
          >
            &ldquo;Okay PilotGPT, is there anything my accountant should double-check in this balance sheet?&rdquo;
          </div>
          <LargeDetailsCard
            check_type={"BS"}
            beta={true}
            result={finalData["balance_sheet"]["BS"]}
            ai_notes={finalData["ai_notes"]["balance_sheet"]}
          ></LargeDetailsCard>
        </div>
      </div>
      <div id="pandl_section" className="flex md:flex-row flex-col mt-10 w-full items-center justify-center md:justify-between ">
        <div className="text-black text-4xl my-4 text-center font-bold" 
          style={{ fontFamily: "Euclid Circular B" }}>
          {" "}
          Profit & Loss Review
        </div>
        <div className="md:w-1/6 w-full flex flex-row items-center justify-center gap-2">
          <div className="text-black text-sm ">
            {" "}
            Your Score:{" "}
            <span className="font-bold mx-1" style={{ color: "#5F2EE5" }}>
              {finalData["sub_scores"]["pandl"]}/100
            </span>
          </div>
          <CircleProgressBarMini
            percentage={finalData["sub_scores"]["pandl"]}
          ></CircleProgressBarMini>
        </div>
      </div>
      <div className="flex w-full flex-row justify-evenly lg:justify-between gap-10 mt-4 flex-wrap lg:px-36">
        <DetailsCard
          check_type={"NEGATIVE_INCOME"}
          result={!finalData["pandl"]["NEGATIVE_INCOME"]}
        ></DetailsCard>
        <DetailsCard
          check_type={"NEGATIVE_EXPENSE"}
          result={!finalData["pandl"]["NEGATIVE_EXPENSE"]}
        ></DetailsCard>
        <DetailsCard
          check_type={"NEGATIVE_COGS"}
          result={!finalData["pandl"]["NEGATIVE_COGS"]}
        ></DetailsCard>
        <DetailsCard
          check_type={"COGS_EXCEEDS_REVENUE"}
          result={!finalData["pandl"]["COGS_EXCEEDS_REVENUE"]}
        ></DetailsCard>
      </div>
      <div className="flex flex-col w-full my-10 justify-center lg:px-36">
        
        <div
          className="flex flex-col md:flex-row items-center text-black text-xl my-4  text-center font-bold"
          style={{ fontFamily: "Euclid Circular B" }}
        >
          {" "}
          AI Review - P&L
          <img
            alt=""
            className="flex items-start justify-start w-4 h-4 mx-1"
            src={DarkSparkle}
          />
          <div
            className=" flex flex-row h-6 rounded-xl mx-2 py-1  px-3 gap-1 text-gray-600 justify-center items-center mb-2 md:mb-0"
            style={{
              fontSize: "11px",
              background: "#E0E0E0",
              width: "fit-content",
              fontFamily: "Euclid Circular B",
              fontWeight: "normal",
              letterSpacing: "1.92px",
            }}
          >
            BETA
          </div>
          <div
            className=" flex flex-row h-6 rounded-xl mx-2 py-1 px-3 gap-1 text-white justify-center items-center"
            style={{
              fontSize: "11px",
              background: finalData["pandl"]["PANDL"] ? "#47BFA4" : "#E2B62D",
              width: "fit-content",
              fontFamily: "Euclid Circular B",
              letterSpacing: "1.92px",
            }}
          >
            {finalData["pandl"]["PANDL"] ? <CheckIcon></CheckIcon> : <CrossIcon></CrossIcon>}
          
            {finalData["pandl"]["PANDL"] ? "LOOKS GOOD" : "INVESTIGATE"}
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div
            className="flex w-full text-black text-sm items-center mb-2"
            style={{ fontFamily: "Euclid Circular B", color: "#656363" }}
          >
            &ldquo;Okay PilotGPT, is there anything my accountant should double-check in this P&L?&rdquo;
          </div>
          <LargeDetailsCard
            check_type={"PANDL"}
            beta={true}
            result={finalData["pandl"]["PANDL"]}
            ai_notes={finalData["ai_notes"]["pandl"]}
          ></LargeDetailsCard>
        </div>
      </div>
      <div id="pilotgpt_section" className="flex md:flex-row flex-col mt-10 w-full items-center justify-center md:justify-between ">
        <div className="flex text-black text-4xl my-4 font-outfit text-center font-bold">
          {" "}
          Business advice from PilotGPT
          <img
            alt=""
            className="flex items-start w-6 h-6 mx-1"
            src={DarkSparkle}
          />
          <div
            className=" flex flex-row h-6 rounded-xl mx-2 py-1  px-3 gap-1 text-gray-600 justify-center items-center mb-2 md:mb-0"
            style={{
              fontSize: "11px",
              background: "#E0E0E0",
              width: "fit-content",
              fontFamily: "Euclid Circular B",
              fontWeight: "normal",
              letterSpacing: "1.92px",
            }}
          >
            BETA
          </div>
        </div>
      </div>
      <div
        className="flex w-full text-black text-sm items-center lg:px-36"
        style={{ fontFamily: "Euclid Circular B", color: "#656363" }}
      >
        &ldquo;Okay PilotGPT, what can I do to make my business stronger?&rdquo;
      </div>
      <div className="flex w-full flex-row justify-evenly gap-10 mt-4 flex-wrap lg:px-36">
        <PilotGPTCard data={finalData["ai_notes"]["pilotgpt"]} />
      </div>
    </div>
  );
};

export default CardsPage;
