import "../index.css";
import { useState, useEffect } from "react";
import CardsPage from "./checks";
import { MarketoFormUpload } from "../helpers";
import { CircleProgressBar } from "./CircularProgressBar";
import { ProgressBar } from "./ProgressBar";
import srcMobile from "../assets/pilot_white.svg";
import srcDesktop from "../assets/bookCheckFull_white.svg";
import html2canvas from 'html2canvas';
import Footer from "./Footer";


const Results = ({ companyData, finalData }) => {
  const ResultsData = finalData;
  const [currentSrc, setCurrentSrc] = useState(srcDesktop);

  function isIOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // Check if the device is iOS (iPhone, iPad, iPod)
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  }

  const capture = () => {
    html2canvas(document.getElementById("root"), {
      height: document.getElementById("scrollable_view").scrollHeight + 100,
      windowHeight: document.getElementById("scrollable_view").scrollHeight + 100
    }).then((canvas) => {
      canvas.toBlob((blob) => {
        const link = document.createElement('a');
        if (isIOS()) {
          //var blob = new Blob(blob, { type: 'video/webm' });
          let url = window.webkitURL.createObjectURL( blob );
          window.open(url, "_blank");
        } else {
          link.href = URL.createObjectURL(blob);
          link.download = 'Pilot_Quality_Check.png';
          link.click();
        }
      }, 'image/png');
    });
  };

  useEffect(() => {
    let all_data = {
      'company_data' : companyData["QueryResponse"]["CompanyInfo"][0],
      'final_data' : finalData
    }
    MarketoFormUpload({
      email: companyData["QueryResponse"]["CompanyInfo"][0]["CustomerCommunicationEmailAddr"]["Address"],
      notes: JSON.stringify(all_data),
    });
  }, [companyData]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setCurrentSrc(srcMobile);
      } else {
        setCurrentSrc(srcDesktop);
      }
    };

    // Set the initial image
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div id="report_page" className="flex flex-col w-full h-full overflow-y-scroll overflow-x-hidden md:overflow-hidden">
      <div id="header_parent_section" className="flex w-full h-auto shadow-xl items-center justify-center" style={{ backgroundColor: "#5F2EE5" }}>
        <div id="header_section" className="flex flex-row w-full h-32 py-2 px-4 md:px-0 max-w-6xl md:py-8 items-center">
          <img src={currentSrc} alt="" className="flex w-1/5 md:h-auto" onClick={() => window.location.href = "https://qualitycheck.pilot.com"} />
          <div
            className="h-1/2 sm:h-full mx-8"
            style={{ borderLeft: "1px solid #3C2D89" }}
          ></div>
          <div className="flex-col md:flex hidden ">
            <div
              className="flex text-xl lg:text-3xl text-white font-bold"
              style={{ fontFamily: "Euclid Circular B" }}
            >
              Talk with our accounting experts
            </div>
            <div
              className="flex text-sm lg:text-lg text-white "
              style={{ fontFamily: "Euclid Circular B " }}
            >
              Book a call with the Pilot team to find out how we can improve your
              books.
            </div>
          </div>
          <button id="header_schedule_button"
            className="ml-auto w-40  cursor-pointer outline-white  outline outline-1 rounded-md text-base rounded-sm text-white items-center justify-center py-2 transition duration-500 hover:scale-125 "
            style={{ backgroundColor: "#5F2EE5" }}
            href="https://pilot.chilipiper.com/book/me/brieanne-runsten?type=follow-up-to-quality-check"
            onClick={() => window.open("https://pilot.chilipiper.com/book/me/brieanne-runsten?type=follow-up-to-quality-check", '_blank')}
          >
            Schedule a call
          </button>
        </div>
      </div>
      <div className="flex flex-col w-full md:overflow-y-scroll">
        <div className="flex lg:flex-row flex-col w-full items-center justify-center bg-white py-10 px-16 md:px-24 shadow-xl" style={{ backgroundColor: "#5F2EE5"}}>
          <div id="scoring_section" className="flex w-full justify-between max-w-6xl flex-col lg:flex-row items-center">
            <CircleProgressBar
              percentage={finalData["score"]}
              grade={finalData["grade"]}
            ></CircleProgressBar>
            <div className="flex flex-col my-12 gap-2 items-center w-full lg:w-1/3">
              <div className="text-white text-xs self-start flex mt-3 flex-row justify-between  w-full">
                <div> Balance Sheet Review</div>
                <div style={{ fontWeight: 700}}> {finalData["sub_scores"]["balance_sheet"]}/100</div>
              </div>
              <ProgressBar
                value={finalData["sub_scores"]["balance_sheet"]}
                height="10px"
              ></ProgressBar>
              <div className="text-white text-xs self-start mt-3 flex flex-row justify-between  w-full">
                <div>P&L Review</div>
                <div style={{ fontWeight: 700}}> {finalData["sub_scores"]["pandl"]}/100</div>
              </div>
              <ProgressBar
                value={finalData["sub_scores"]["pandl"]}
                height="10px"
              ></ProgressBar>
            </div>
            {!isIOS() ? <div id="share_team_card" className="w-full lg:w-auto lg:max-w-sm flex h-full flex-col items-start justify-center bg-white p-8 rounded-xl " style={{ backgroundColor: "#3C2D89"}}>
              <div className="flex text-white w-full text-left pb-2" style={{ fontFamily: "Euclid Circular B", fontWeight: 700 }}>
                Want to share this with your team?
              </div>
              <div className="flex text-white w-full text-left pb-4" style={{ fontFamily: "Euclid Circular B", fontWeight: "normal" }}>
                Download a copy of your Quality Check report here.
              </div>
              <button
                className="w-40 cursor-pointer outline-white items-center justify-center rounded-lg text-base rounded-sm text-white py-2 hover:outline outline-2 outline-white animate-outline "
                style={{ backgroundColor: "#5F2EE5" }}
                onClick={capture}
              >
                Download Report
              </button>
            </div> : null}
          </div>
        </div>
        <div id="scrollable_view" className="flex flex-col md:flex-row">
          <div className="flex flex-col w-full h-screen">
            <div className="flex flex-col items-center justify-center" style={{ backgroundColor: "#F3F5FA" }}>
              <CardsPage finalData={ResultsData}></CardsPage>
              <div className="flex flex-col md:flex-row  w-full h-60 bg-cover pb-24 bg-no-repeat p-20 items-center justify-center mb-8 md:mb-0" style={{ 
                backgroundColor: "#5f2ee5",
                backgroundImage: `url("https://assets-global.website-files.com/60a5136f2c6c8e4fb3a130d9/6222699738e3990f8c2b255e_get-in-touch-background.svg")`,
                backgroundPosition: "100% 100%"
              }}>
                <div className="flex flex-col w-full lg:w-1/2 items-center justify-center pb-4 md:pb-0">
                  <div className="flex w-full text-xl md:text-4xl text-white" style={{ fontFamily: "Euclid Circular B", fontWeight: 700 }}>
                    See what Pilot can do for you
                  </div>
                  <div className="flex w-full text-sm md:text-lg text-white" style={{ fontFamily: "Euclid Circular B", fontWeight: "normal" }}>
                    Get the peace of mind that comes from partnering with our experienced finance team.
                  </div>
                </div>
                <div 
                  className="flex w-full lg:w-1/6 p-2 bg-emerald-500 text-white text-center text-lg items-center justify-center rounded-lg cursor-pointer hover:outline outline-1 outline-white "
                  style={{ fontFamily: "Euclid Circular B", fontWeight: "normal" }}
                  onClick={() => window.open("https://pilot.chilipiper.com/book/me/brieanne-runsten?type=follow-up-to-quality-check", '_blank')}
                >
                  Contact Sales
                </div>
              </div>
              <Footer></Footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
