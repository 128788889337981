import React from "react";
import { Card, CardBody, Typography } from "@material-tailwind/react";
import checks from "../../assets/json/checks.json";
import Sparkle from "../../assets/sparkle.svg";

const LargeDetailsCard = ({
  check_type,
  result,
  beta = false,
  ai_notes = null,
}) => {
  return (
    <Card
      className={
        "w-full h-80 text-left py-6 px-6 shadow-sm items-center flex rounded-xl shadow-xl outline outline-1 " +
        (ai_notes ? "h-auto" : "")
      }
      style={{ outlineColor: "#5F2EE5", whiteSpace: "pre-line"}}
    >
      <CardBody className=" w-full">
        {beta ? (
          <div
            className="flex absolute right-0 top-0 w-28 text-center h-9 text-xs items-center justify-center"
            style={{
              backgroundColor: "#E3DAFD",
              borderRadius: "0px 12px 0px 8px",
              fontFamily: "Euclid Circular B",
              color: "#5F2EE5",
            }}
          >
            <img alt="" className="pr-2" src={Sparkle} />
            PilotGPT
          </div>
        ) : null}
        {/* <Typography
          // variant="h5"
          color="#3F3C3D"
          className=" text-lg mb-4"
          style={{ fontFamily: "Euclid Circular B", fontWeight: 700 }}
        >
          {result ? "PilotGPT thinks this looks pretty good. Some thoughts:" :"PilotGPT has some concerns. Some thoughts:"}
        </Typography> */}

        <Typography className="text-base" style={{color:"#656363"}}>
          {ai_notes
            ? ai_notes
            : result
            ? checks[check_type]["description"]["pass"]
            : checks[check_type]["description"]["fail"]}
        </Typography>
      </CardBody>
    </Card>
  );
};

export default LargeDetailsCard;
