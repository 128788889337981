import React from "react";

const Footer = () => {
  return (
    <footer
      className=" text-left w-full md:w-3/4 mx-auto px-6 md:px-8"
      style={{ color: "#999", fontFamily: "SpaceMono", fontSize: "13px" }}
    >
      <p className="mt-8 mb-12 leading-loose">
      Pilot Quality Check is a free service provided AS-IS, without any
      express or implied warranty. Pilot will use your financial data
      only to do the check and will not retain it. To the extent Pilot
      is exposed to your financial data while performing the check,
      Pilot commits to keep it confidential. We will obtain your QBO
      admin's details (name, email) and may contact them with marketing
      communications. They can unsubscribe at any time.
      </p>
      <div
        className=" my-10 flex md:flex-row flex-col gap-4"
        style={{ color: "#999" }}
      >
        © Pilot.com, Inc.
        <a
          className=""
          href="https://www.pilot.com/terms/subscription-agreement"
        >
          Customer Terms
        </a>
        <a className="" href="https://www.pilot.com/terms/privacy">
          Privacy Policy
        </a>
      </div>
      <p className="mt-8 mb-12 leading-loose">
        Pilot is a provider of back-office services, including bookkeeping,
        controller services, and CFO services.<br></br> Pilot is not a public
        accounting firm and does not provide services that would require a
        license to practice public accountancy.
      </p>
    </footer>
  );
};

export default Footer;
