import { useEffect, useState } from "react";
import CircularProgress from "@mui/joy/CircularProgress";
import { useCountUp } from "use-count-up";
export const CircleProgressBar = ({ percentage, grade }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { value: value1 } = useCountUp({
    isCounting: isLoading,
    duration: 1,
    start: 0, 
    end: percentage / 10,
    onComplete: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    setIsLoading(true);
  }, []);

  return (
    <CircularProgress
      sx={{
        "--CircularProgress-size": "220px",
        "--CircularProgress-progressColor": "#FFFFFF",
        "--CircularProgress-trackColor": "#3C2D89",
        "--_progress-thickness": "20px",
      }}
      determinate
      value={parseFloat(value1)}
      color="success"
      thickness={20}
    >
      <div className="flex flex-col gap-0 text-center">
        <div
          className=""
          style={{
            fontSize: "100px",
            color: "white",
            fontFamily: "Euclid Circular B",
            fontWeight: "600",
            lineHeight: "1",
          }}
        >
          {grade}
          <div style={{ color: "white", fontSize: "14px" }}>your grade</div>
        </div>
      </div>
    </CircularProgress>
  );
};
