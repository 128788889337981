import Sparkle from "../../assets/sparkle.svg";
import { Card, CardBody, Typography } from "@material-tailwind/react";

export const PilotGPTCard = ({ data }) => {
    return(
        <Card
          className={
            "w-full h-80 text-left py-6 px-6 shadow-sm items-center flex rounded-xl shadow-xl outline outline-1 h-auto"
          }
          style={{ outlineColor: "#5F2EE5", fontFamily: "Euclid Circular B", fontWeight: "normal", whiteSpace: "pre-line"}}
        >
            <CardBody className=" w-full">
                <div
                  className="flex absolute right-0 top-0 w-28 text-center h-9 text-xs items-center justify-center"
                  style={{
                    backgroundColor: "#E3DAFD",
                    borderRadius: "0px 12px 0px 8px",
                    fontFamily: "Euclid Circular B",
                    color: "#5F2EE5",
                  }}
                >
                  <img alt="" className="pr-2" src={Sparkle} />
                  PilotGPT
                </div>
                <Typography className="text-base" style={{color:"#656363"}}>
                  {data}
                </Typography>
            </CardBody>
        </Card>
    )
}