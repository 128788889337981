import React, { useEffect, useState } from "react";
import Hero from "../components/hero";
import Results from "../components/results";
import { InsightsGen } from "../api";
import Loader from "../components/Loader/loader";
import ErrorMessage from "../components/error";

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

const Home = () => {
  const [qboParams, setQboParams] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [finalData, setFinalData] = useState(null);
  const [error, setError] = useState(null);
  const [checklist, setChecklist] = useState({
    logged_in: false,
    runway: false,
    balance_sheet_review: false,
    pandl_review: false,
    additional_checks: false,
    final: false,
  });

  useEffect(() => {
    const qboCode = params.code;
    const state = params.state;
    const realmId = params.realmId;
    
    // Remove params from URL to avoid user refresh errors if QBO code exists
    if (qboCode || state || realmId) {
      const currentUrl = window.location.href;
      const baseUrl = currentUrl.split('?')[0];
      window.history.pushState({}, '', baseUrl);
    }
    
    setQboParams({ code: qboCode, state: state, realmId: realmId });
  }, []);

  useEffect(() => {
    if (qboParams && qboParams.code)
      InsightsGen(
        qboParams.code,
        qboParams.realmId,
        setCompanyData,
        setChecklist,
        setFinalData,
        setError
      );
  }, [qboParams]);

  return (
    <div className="flex flex-col w-full h-full bg-white">
      {!error ? (
        qboParams &&
        qboParams.code === null &&
        checklist.logged_in === false ? (
          <Hero />
        ) : (
          // <Loader checklist={checklist} />
          <Loader checklist={checklist} />
        )
      ) : !checklist.final ? (
        <ErrorMessage error={error} />
      ) : null}
      {qboParams && companyData && finalData && (
        <Results companyData={companyData} finalData={finalData} />
      )}
    </div>
  );
};

export default Home;
