
const ErrorMessage = ({ error }) => {
    return (
      <div className="flex flex-col h-full mx-8 md:mx-16 sm:mx-20 my-9 sm:my-14">
        <div className="flex flex-col w-full h-full items-center justify-center text-center">
            <h1
              className="text-3xl md:text-6xl leading-snug text-black font-bold md:mb-3 my-3 font-outfit font-bold 		"
              style={{
                color: "#5F2EE5",
                fontFamily: "Euclid Circular B",
                fontWeight: 700,
              }}
            >
              Uh-oh! 🫣
            </h1>
            <h1 className="w-1/3 text-xl text-black font-bold mb-20 font-outfit font-bold" style={{ fontFamily: "Euclid Circular B", fontWeight: "normal" }}>
                <p>
                Something went wrong while checking your books.
                </p>
                <p>
                Please send me an e-mail (&nbsp;<a style={{ color: "blue", textDecoration: "underline", fontFamily: "Euclid Circular B", fontWeight: "normal" }} href="mailto:waseem@pilot.com">waseem@pilot.com</a>
                &nbsp;) with your company name and I'll take a look.
                </p>
            </h1>
            <button
                className="flex w-48 cursor-pointer outline-white outline rounded-md text-base rounded-md text-white items-center justify-center py-6 transition duration-500 hover:scale-125 "
                style={{ backgroundColor: "#825beb" }}
                onClick={() => window.location.replace("/")}
            >
                Go Home
            </button>
        </div>
      </div>
    );
};

export default ErrorMessage;
