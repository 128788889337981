import sixDots from "../assets/six.svg";
import * as Accordion from "@radix-ui/react-accordion";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import srcMobile from "../assets/bookcheck_logo.svg";
import srcDesktop from "../assets/bookCheckFull.svg";
import classNames from "classnames";
import { OAuthURL } from "../api";
import { forwardRef, useState, useEffect } from "react";
import Footer from "./Footer";

const Hero = () => {
  const [faq, setFaq] = useState(false);
  const [currentSrc, setCurrentSrc] = useState(srcDesktop);
  const [connectLoading, setConnectLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setCurrentSrc(srcMobile);
      } else {
        setCurrentSrc(srcDesktop);
      }
    };

    // Set the initial image
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col  w-full h-full">
      <div className="md:px-16 px-4 py-8 flex w-full top-0 flex-row justify-between bg-white shadow-xl">
        <div className="flex w-40 md:w-96">
          <img src={currentSrc} alt="" onClick={() => window.open("https://pilot.com", "_blank")}></img>
        </div>
        <div
          className="flex cursor-pointer md:h-10 h-7 text-sm rounded-sm items-center justify-center px-8"
          style={{
            color: "#825beb",
            outline: "1px solid #825beb",
            fontFamily: "Euclid Circular B",
          }}
          onClick={() => {
            setFaq(!faq);
          }}
        >
          FAQ
        </div>
      </div>
      {faq ? (
        <div className="flex flex-wrap flex-col w-full h-full items-center  justify-top pt-40">
          <Accordion.Root
            className=" md:w-[450px]  w-5/6 rounded-md "
            type="single"
            defaultValue=""
            collapsible
          >
            <div
              className="flex flex-row cursor-pointer"
              onClick={() => {
                setFaq(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#3C2D89"
                className="w-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
              <div className="mx-2 text-sm " style={{ color: "#3C2D89" }}>
                Back
              </div>
            </div>

            {/* <AccordionItem value="item-1">
              <AccordionTrigger>Who made this?</AccordionTrigger>
              <AccordionContent>
                Quality Check is a free product offered by Pilot.com, the largest provider of accounting services to startups in the United States.
              </AccordionContent>
            </AccordionItem> */}

            <AccordionItem value="item-2">
              <AccordionTrigger>Do you store my financial data?</AccordionTrigger>
              <AccordionContent>
                No. Your financial data is used to perform the check, but we don't retain it.
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-3">
              <AccordionTrigger>Do these checks use AI?</AccordionTrigger>
              <AccordionContent>
                Some are entirely deterministic and don't use AI at all. Others use AI to identify patterns in your books that might indicate a problem.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-4">
              <AccordionTrigger>How can I learn more about what Pilot offers?</AccordionTrigger>
              <AccordionContent>
                Check out our website at <a href="https://pilot.com">pilot.com</a> or <a href="https://pilot.com/talk-to-expert">book some time with us</a> to learn more.
              </AccordionContent>
            </AccordionItem>
          </Accordion.Root>
        </div>
      ) : (
        <div className="flex flex-wrap w-full md:h-full h-5/6 justify-center items-center overflow-scroll">
          <div className="flex flex-col w-full md:w-3/4 px-8 text-left justify-center  text-left">
            <img className="h-8 my-8 self-start" src={sixDots} alt=""></img>
            <h1
              className="text-3xl md:text-6xl leading-snug text-black font-bold md:mb-3 my-3 font-outfit font-bold 		"
              style={{
                color: "#5F2EE5",
                fontFamily: "Euclid Circular B",
                fontWeight: 700,
              }}
            >
              Unsure of your books? <br></br> Get our fast, free diagnostic
              instantly.
            </h1>
            <p
              className="flex text-sm md:text-2xl w-full md:w-2/3 text-left md:my-2 mt-4 font-bold "
              style={{
                color: "#3C2D89",
                fontFamily: "Euclid Circular B",
                fontWeight: "normal",
              }}
            >
              Pilot Quality Check analyzes your existing books for common
              mistakes and identifies areas for improvement.
            </p>

            <button
              className="flex p-3 mt-10 mb-3 text-center justify-center text-white rounded-md cursor-pointer transition duration-500 hover:scale-125 "
              style={{
                background: "#5F2EE5",
                width: "270px",
                fontFamily: "Euclid Circular B",
                fontWeight: "normal",
              }}
              onClick={async () => {
                setConnectLoading(true);
                let url = await OAuthURL();
                window.location.replace(url);
                setConnectLoading(false);
              }}
              alt=""
            >
              { connectLoading ? (
                <div className="flex mx-2" role="status">
                  <svg aria-hidden="true" className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : ("Connect To QuickBooks")}
            </button>
            <a
              className="flex text-xs md:text-base w-full md:w-2/3 text-left md:my-2 mt-4 font-bold underline"
              onClick={() => window.open("https://youtu.be/Xvqnzr82XVc", "_blank")}
              style={{
                color: "#825beb",
                fontFamily: "Euclid Circular B",
                fontWeight: "normal",
                cursor: "pointer",
              }}
            >
              Don't have QuickBooks? Watch a demo here.
            </a>
            {/* <hr className="h-px my-8 bg-gray-200  border-0 dark:bg-gray-700"></hr> */}
            {/* <p
              className=" leading-5 md:w-5/6  w-full md:text-left"
              style={{
                fontSize: "14px",
                color: "#656363",
                fontFamily: "Euclid Circular B",
                fontWeight: "normal",
              }}
            >
              Pilot Quality Check is a free service provided AS-IS, without any
              express or implied warranty. Pilot will use your financial data
              only to do the check and will not retain it. To the extent Pilot
              is exposed to your financial data while performing the check,
              Pilot commits to keep it confidential. We will obtain your QBO
              admin's details (name, email) and may contact them with marketing
              communications. They can unsubscribe at any time.
            </p> */}
          </div>
          <Footer></Footer>
        </div>
      )}
    </div>
  );
};

const AccordionItem = forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Item
      className={classNames(
        "   mt-4 text-sm overflow-hidden first:mt-0 rounded-md focus-within:relative  text-black rounded-md",
        className
      )}
      style={{
        background: "#F3F5FA",
        fontFamily: "Euclid Circular B",
        fontWeight: 700,
      }}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Accordion.Item>
  )
);

const AccordionTrigger = forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="flex">
      <Accordion.Trigger
        className={classNames(
          "  hover:bg-mauve2 font-bold group flex h-[45px] flex-1 cursor-default items-center justify-between bg-white p-8 text-sm leading-none  outline-none",
          className
        )}
        style={{
          background: "#F3F5FA",
          color: "#3C2D89",
          fontFamily: "Euclid Circular B",
          fontWeight: 700,
        }}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <ChevronDownIcon
          className=" ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-180"
          aria-hidden
          width={24}
          height={28}
        />
      </Accordion.Trigger>
    </Accordion.Header>
  )
);

const AccordionContent = forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames(
        " bg-mauve2 data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden text-sm",
        className
      )}
      style={{
        background: "#F3F5FA",
        color: "#3C2D89",
        fontFamily: "Euclid Circular B",
        fontWeight: "normal",
      }}
      {...props}
      ref={forwardedRef}
    >
      <div className="pb-6 text-xs px-8">
        <hr className="h-px bg-gray-200 mb-4 border-0 dark:bg-gray-700"></hr>

        {children}
      </div>
    </Accordion.Content>
  )
);

export default Hero;
