import { useEffect, useState } from "react";
import CircularProgress from "@mui/joy/CircularProgress";
import { useCountUp } from "use-count-up";
export const CircleProgressBarMini = ({ percentage }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { value: value1 } = useCountUp({
    isCounting: isLoading,
    duration: 1,
    start: 0,
    end: percentage,
    onComplete: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    setIsLoading(true);
  }, []);

  return (
    <CircularProgress
      sx={{
        "--CircularProgress-size": "30px",
        "--CircularProgress-progressColor": "#47BFA4",
        "--CircularProgress-trackColor": "#E0E0E0",
        "--_progress-thickness": "6px",
      }}
      determinate
      value={parseFloat(value1)}
      color="success"
      thickness={6}
    ></CircularProgress>
  );
};
