const MarketoFormUpload = ({ email, notes }) => {
    window.MktoForms2.loadForm("//go.pilot.com", "754-NWB-621", 1565, (form) => {
        form.onSubmit((form) => {
            //console.log("SUBMITTED");
        });
        form.onSuccess((values, followUpUrl) => {
            //console.log("Form submission successful");
            return false;
        });
        form.vals({ "Email" : email, "ML_clean_up_notes__c" : notes});
    });
    window.MktoForms2.whenReady(function (form) {
        form.submit();
    });
}

const DummyFinalData = () => {
    return (
        {
            "score": 692,
            "grade": "B",
            "sub_scores": {
                "health": 100,
                "pandl": 80,
                "balance_sheet": 50,
                "additional_checks": 100
            },
            "runway": {
                "RUNWAY": {
                    "BURN_RATE": 315,
                    "RUNWAY_IN_MONTHS": 12,
                    "ENDING_CASH": [
                        {
                            "month": 10,
                            "ending_cash": "5008.55"
                        },
                        {
                            "month": 11,
                            "ending_cash": "4063.52"
                        },
                        {
                            "month": 12,
                            "ending_cash": "4063.52"
                        }
                    ]
                }
            },
            "balance_sheet": {
                "RECONCILED_BANK_ACCOUNTS": false,
                "ZERO_OPENING_BALANCE": false,
                "NEGATIVE_BALANCES": false,
                "DEPRECIATION_ENTRY": false,
                "NEGATIVE_INVENTORY": false,
                "BS": false
            },
            "pandl": {
                "NEGATIVE_INCOME": true,
                "NEGATIVE_EXPENSE": true,
                "NEGATIVE_COGS": true,
                "COGS_EXCEEDS_REVENUE": true,
                "PANDL": true
            },
            "additional_checks": {
                "QBO_SETUP": true,
                "ACCOUNTING_STANDARDS": true
            },
            "final_summary": {
                "final_summary": "The user has a total score of 692 and a grade of B in their accounting books. Their accounting health, profit and loss, and balance sheet scores are 100, 80, and 50 respectively. Additionally, they have passed all additional checks related to their accounting setup."
            }
        }
    );
}

export { MarketoFormUpload, DummyFinalData }