import { Card, CardBody, Typography } from "@material-tailwind/react";
import checks from "../../assets/json/checks.json";
import Sparkle from "../../assets/sparkle.svg";

export const DetailsCard = ({
  check_type,
  result,
  beta = false,
  ai_notes = null,
}) => {
  return (
    <Card
      className={
        "w-80 h-80 text-left py-4 px-4 shadow-sm items-center flex rounded-xl shadow-lg " +
        (ai_notes ? "h-auto" : "")
      }
    >
      <CardBody className=" ">
        {beta ? (
          <div
            className="flex absolute right-0 top-0 w-1/3 text-center h-9 text-xs items-center justify-center"
            style={{
              backgroundColor: "#E3DAFD",
              borderRadius: "0px 12px 0px 8px",
              fontFamily: "Euclid Circular B",
              color: "#5F2EE5",
            }}
          >
            <img alt="" className="pr-2" src={Sparkle} />
            PilotGPT
          </div>
        ) : null}
        <div className="flex flex-row mt-4">
          <div
            className=" flex flex-row rounded-xl mb-4 py-1  px-3 gap-1 text-white"
            style={{
              fontSize: "11px",
              background: result ? "#47BFA4" : "#FF879A",
              width: "fit-content",
              fontFamily: "Euclid Circular B",
              letterSpacing: "1.92px",
            }}
          >
            {result ? <CheckIcon></CheckIcon> : <CrossIcon></CrossIcon>}

            {result ? "PASS" : "FAIL"}
          </div>
          {beta ? (
            <div
              className=" flex flex-row rounded-xl mx-2 mb-4 py-1  px-3 gap-1 text-gray-600"
              style={{
                fontSize: "11px",
                background: "#E0E0E0",
                width: "fit-content",
                fontFamily: "Euclid Circular B",
                letterSpacing: "1.92px",
              }}
            >
              BETA
            </div>
          ) : null}
        </div>
        <Typography
          variant="h5"
          className=" text-lg mb-4"
          style={{ fontFamily: "Euclid Circular B", fontWeight: 700, color: "#3F3C3D" }}
        >
          {checks[check_type]["title"]}
        </Typography>

        <Typography className="text-base" style={{ color: "#656363"}}>
          {ai_notes
            ? ai_notes
            : result
            ? checks[check_type]["description"]["pass"]
            : checks[check_type]["description"]["fail"]}
        </Typography>
      </CardBody>
    </Card>
  );
};

export const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-4 h-4 "
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m4.5 12.75 6 6 9-13.5"
      />
    </svg>
  );
};

export const CrossIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-4 h-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18 18 6M6 6l12 12"
      />
    </svg>
  );
};
