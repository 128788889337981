import "./loader.css";
import BookCheckLogo from "../../assets/bookCheckFull.svg";

const Loader = ({ checklist }) => {
  return !checklist.final ? (
    <div className="flex flex-col w-full pb-24 h-full items-center overflow-y-scroll">
      <div className="md:px-16 px-4 py-8 flex w-full top-0 flex-row justify-between bg-white shadow-xl">
        <div className="flex w-40 sm:w-60 xl:w-80">
          <img src={BookCheckLogo} alt=""></img>
        </div>
      </div>
      <div className="flex md:w-2/3 h-full items-center md:justify-center mt-10 md:mt-0  flex-col md:flex-row">
        <div
          className="flex flex-col items-center md:py-24 w-full"
          style={{ maxWidth: "450px" }}
        >
          <h1
            className=" md:text-6xl text-5xl md:text-left text-center leading-normal text-black font-bold md:my-12 md:mb-3    font-bold 		"
            style={{
              color: "#5F2EE5",
              fontFamily: "Euclid Circular B",
              fontWeight: 700,
            }}
          >
            We’re checking <br></br> your books!
          </h1>
          <div
            className="flex flex-col w-4/5 md:w-full h-auto gap-4 p-4 rounded-xl mt-2 items-center justify-center"
            style={{
              backgroundColor: "#F3F5FA",

              fontFamily: "Euclid Circular B",
            }}
          >
            <div className="w-full h-full text-md text-black px-2 items-center justify-center text-left">
            🚀 Did you know: Pilot is the largest startup-focused accounting firm in the US, with ~2,000 clients?
            </div>
          </div>
        </div>
        <ChecklistComponent checklist={checklist}></ChecklistComponent>
      </div>
    </div>
  ) : null;
};

export default Loader;

const checklistOrder = [
  "logged_in",
  "runway",
  "balance_sheet_review",
  "pandl_review",
  "additional_checks",
  "final",
];

const isNextItem = (checklist, currentIndex) => {
  if (checklist[checklistOrder[currentIndex]]) {
    return false;
  }
  for (let i = 0; i < currentIndex; i++) {
    if (!checklist[checklistOrder[i]]) {
      return false; // Previous item in the order is not completed
    }
  }
  return true; // All previous items in the order are completed
};

const getOpacityClass = (index, condition) => {
  if (condition) return 1; // Full opacity when condition is true
  const baseOpacity = 100;
  const decrement = 20; // Decrease opacity by 20 for each subsequent item
  return Math.max(baseOpacity - decrement * index, 20) / 100; // Minimum opacity is 20
};

const ChecklistItem = ({ condition, children, index, isNext }) => (
  <div
    className={`flex w-full h-1/8 p-4 rounded-xl`}
    style={{
      background: isNext ? "#F3F5FA" : "",
    }}
  >
    {condition || isNext ? (
      <div className={`flex circle-loader ${condition ? "load-complete" : ""}`}>
        <div
          className={`flex ${
            condition
              ? "checkmark-after checkmark-after-draw"
              : "checkmark draw"
          }`}
        ></div>
      </div>
    ) : (
      <div className={`flex w-4 h-4`}></div>
    )}

    <div
      className="flex items-center mx-4"
      style={{
        opacity: getOpacityClass(index, condition),
        fontFamily: "Euclid Circular B",
        fontSize: "16px",
        fontWeight: "100",
      }}
    >
      {children}
    </div>
  </div>
);

const ChecklistComponent = ({ checklist }) => (
  <div className="flex flex-col md:w-1/3 h-full text-sm rounded-xl p-4 items-center justify-top md:justify-center text-black">
    <ChecklistItem
      condition={checklist.logged_in}
      index={0}
      isNext={isNextItem(checklist, 0)}
    >
      Logged in
    </ChecklistItem>
    <ChecklistItem
      condition={checklist.runway}
      index={1}
      isNext={isNextItem(checklist, 1)}
    >
      {!checklist.runway ? "Calculating your runway..." : "Runway calculated"}
    </ChecklistItem>
    <ChecklistItem
      condition={checklist.balance_sheet_review}
      index={2}
      isNext={isNextItem(checklist, 2)}
    >
      {!checklist.balance_sheet_review
        ? "Reviewing your balance sheet..."
        : "Balance Sheet reviewed"}
    </ChecklistItem>
    <ChecklistItem
      condition={checklist.pandl_review}
      index={3}
      isNext={isNextItem(checklist, 3)}
    >
      {!checklist.pandl_review ? "Reviewing your P&L..." : "P&L reviewed"}
    </ChecklistItem>
    <ChecklistItem
      condition={checklist.additional_checks}
      index={4}
      isNext={isNextItem(checklist, 4)}
    >
      {!checklist.additional_checks
        ? "PilotGPT taking a look..."
        : "PilotGPT has reviewed"}
    </ChecklistItem>
    <ChecklistItem
      condition={checklist.final}
      index={5}
      isNext={isNextItem(checklist, 5)}
    >
      {!checklist.final ? "Running some final checks..." : "All done!"}
    </ChecklistItem>
  </div>
);
